'use strict';
window.dndNextLevel = window.dndNextLevel || {};
dndNextLevel.main = dndNextLevel.main || {};

dndNextLevel.main.coreFunctionality = () => {
    dndNextLevel.main.initApp();
    dndNextLevel.main.initServicesDropdown();
    dndNextLevel.main.copywright();
}

dndNextLevel.main.initServicesDropdown = () => {
    const button = document.getElementById("services-menu-button");
    const menu = document.querySelector("[role='menu']");


    button.addEventListener("click", function () {
        const expanded = button.getAttribute("aria-expanded") === "true" || false;
        button.setAttribute("aria-expanded", !expanded);
        menu.classList.toggle("hidden");
    });

    document.addEventListener("click", function (event) {
        if (!button.contains(event.target) && !menu.contains(event.target)) {
            button.setAttribute("aria-expanded", "false");
            menu.classList.add("hidden");
        }
    });
}


dndNextLevel.main.initApp = () => {
    const hamburgerBtn = document.getElementById('hamburger-button');
    const mobileMenu = document.getElementById('mobile-menu');

    const toggleMenu = () => {
        mobileMenu.classList.toggle('hidden');
        mobileMenu.classList.toggle('flex');
        hamburgerBtn.classList.toggle('toggle-btn')
    }

    hamburgerBtn.addEventListener('click', toggleMenu);
    mobileMenu.addEventListener('click', toggleMenu);
}

dndNextLevel.main.copywright = () => {
    document.getElementById('copyright').textContent = `${new Date().getFullYear()}`;
}

document.addEventListener('DOMContentLoaded', dndNextLevel.main.coreFunctionality);