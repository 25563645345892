'use strict';
window.dndNextLevel = window.dndNextLevel || {};
dndNextLevel.main = dndNextLevel.main || {};

dndNextLevel.main.coreFunctionality = () => {
    dndNextLevel.main.initApp();
    dndNextLevel.main.copywright();

}

dndNextLevel.main.initApp = () => {
    const hamburgerBtn = document.getElementById('hamburger-button');
    const mobileMenu = document.getElementById('mobile-menu');

    const toggleMenu = () => {
        mobileMenu.classList.toggle('hidden');
        mobileMenu.classList.toggle('flex');
        hamburgerBtn.classList.toggle('toggle-btn')
    }

    hamburgerBtn.addEventListener('click', toggleMenu);
    mobileMenu.addEventListener('click', toggleMenu);
}

dndNextLevel.main.copywright = () => {
    document.getElementById('copyright').textContent = `${new Date().getFullYear()}`;
}

document.addEventListener('DOMContentLoaded', dndNextLevel.main.coreFunctionality);